<template>
  <div class="h5-container">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>
    <div class="tab-bar-outer">
      <tab-bar></tab-bar>
    </div>
  </div>
</template>
<script>
import tabBar from '@/components/userTabbar'
export default {
  name: 'home',
  components: {
    tabBar
  },
  data() {
    return {
      transitionName: ''
    }
  },
  watch: {
    $route(to, from) {
      if (to.meta.index < from.meta.index) {
        this.transitionName = 'slide-right'
      } else if (to.meta.index > from.meta.index) {
        this.transitionName = 'slide-left'
      }
    }
  }
}
</script>