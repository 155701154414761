<template>
  <div class="tab-bar user">
    <div
      class="tab"
      :class="{ active: tabIndex == i }"
      v-for="(tab, i) in tabArr"
      :key="i"
      @click="changeTab(tab, i)"
    >
      <div class="icon">
        <img :src="iconUrl1" alt="" v-if="tabIndex !== 0 && i == 0" />
        <img :src="iconActiveUrl1" alt="" v-if="i == 0 && tabIndex == 0" />

        <img :src="iconUrl2" alt="" v-if="tabIndex !== 1 && i == 1" />
        <img :src="iconActiveUrl2" alt="" v-if="i == 1 && tabIndex == 1" />

        <Badge :content="carSize" v-if="i == 2 && carSize > 0">
          <img :src="iconUrl3" alt="" v-if="tabIndex !== 2 && i == 2" />
          <img :src="iconActiveUrl3" alt="" v-if="i == 2 && tabIndex == 2" />
        </Badge>
        <template v-else>
          <img :src="iconUrl3" alt="" v-if="tabIndex !== 2 && i == 2" />
          <img :src="iconActiveUrl3" alt="" v-if="i == 2 && tabIndex == 2" />
        </template>

        <img :src="iconUrl4" alt="" v-if="tabIndex !== 3 && i == 3" />
        <img :src="iconActiveUrl4" alt="" v-if="i == 3 && tabIndex == 3" />
      </div>
      <div class="name">{{ $t(`common.${tab.title}`) }}</div>
    </div>
  </div>
</template>
<script>
import { Badge } from 'vant'
import { carList } from '@/api/user'
export default {
  components: {
    Badge
  },
  name: 'tabbar',
  props: {},
  data() {
    return {
      iconUrl1: require('../../assets/imgs/userTab/icon-home.png'),
      iconActiveUrl1: require('../../assets/imgs/userTab/icon-home-active.png'),
      iconUrl2: require('../../assets/imgs/userTab/icon-product.png'),
      iconActiveUrl2: require('../../assets/imgs/userTab/icon-product-active.png'),
      iconUrl3: require('../../assets/imgs/userTab/icon-car.png'),
      iconActiveUrl3: require('../../assets/imgs/userTab/icon-car-active.png'),
      iconUrl4: require('../../assets/imgs/userTab/icon-mine.png'),
      iconActiveUrl4: require('../../assets/imgs/userTab/icon-mine-active.png'),
      tabIndex: 0,
      defaultIcon: '',
      tabRoute: ['wapIndex', 'wapGoods', 'wapCar', 'wapMy'],
      tabArr: [
        { title: 'user-tab1', icon: 'home', path: 'wapIndex' },
        { title: 'user-tab2', icon: 'order', path: 'wapGoods' },
        { title: 'user-tab3', icon: 'task', path: 'wapCar' },
        { title: 'user-tab4', icon: 'team', path: 'wapMy' }
      ]
    }
  },
  watch: {
    $route(val) {
      let name = val.name
      if (this.tabRoute.indexOf(name) > -1) {
        this.init()
      } else {
        this.tabIndex = -1
      }
    },
    initCar() {
      this.initCarList()
    }
  },
  computed: {
    carSize() {
      return this.$store.state.carSize
    },
    initCar() {
      return this.$store.state.initCar
    },
    isLogin() {
      return this.$store.state.isLogin
    }
  },
  mounted() {
    this.init()
    this.initCarList()
  },
  methods: {
    init() {
      let name = this.$route.name
      switch (name) {
        case 'wapIndex':
          this.tabIndex = 0
          break
        case 'wapGoods':
          this.tabIndex = 1
          break
        case 'wapCar':
          this.tabIndex = 2
          break
        case 'wapMy':
          this.tabIndex = 3
          break
        default:
          this.tabIndex = -1
          break
      }
    },
    initCarList() {
      if (!this.isLogin) {
        return
      }
      carList().then((res) => {
        this.$store.state.carSize = res.data.Pagination.totalRecords
      })
    },
    changeTab(tab, i) {
      this.tabIndex = i
      this.$router.push({
        name: tab.path
      })
    }
  }
}
</script>